
export default {
  name: 'App',
  layout: 'blank',
  nuxtI18n: {
    paths: {
      en: '/app',
      it: '/app',
      de: '/app',
    }
  },
  beforeMount(){
    const path = this.$route.query.page ? this.$route.query.page : 'contact'
    this.$router.push({ path: `/${path}`, query: {...this.$route.query, app: true}});
  }
}
